@import '../../../styleFunctions/mixins';

footer {
    background-color: var(--primaryColor);
    padding-block-start: 5rem;
    > div {
        > div {
            padding-inline: 2rem;
            > div {
                h2 {
                    color: var(--whiteColor);
                    font-size: 2.2rem;
                }
                ul {
                    @include flexContainer($alignItems: flex-start, $gap: 1rem);
                    flex-direction: column;
                    margin-block-start: 2rem !important;
                    li {
                        color: var(--whiteColor);
                        font-size: 1.4rem;
                        width: 100%;
                        a {
                            color: inherit !important;
                            max-width: fit-content;
                            padding: 0 !important;
                            font-weight: 500 !important;
                            font-size: inherit !important;
                            border: none !important;
                            &:hover {
                                color: inherit !important;
                            }
                        }
                    }
                }
                .footerSocial {
                    @include flexContainer($justifyContent: flex-start, $alignItems: flex-start, $gap: 1rem);
                    margin-bottom: 0.2rem;
                }
                .appDownload {
                    @include flexContainer($justifyContent: flex-start, $alignItems: flex-start, $gap: 1rem);
                    flex-direction: column;
                    > div {
                        a {
                            img {
                                max-width: 15rem;
                            }
                        }
                        &:nth-of-type(2) {
                            a {
                                img {
                                    border-radius: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bottomFooter {
        margin-block-start: 4rem;
        padding-block: 2rem;
        border-top: 0.1rem solid var(--whiteColor);
        p {
            color: var(--whiteColor);
            font-size: 1.6rem;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 990px) {
    footer {
        > div {
            > div {
                > div {
                    &:nth-of-type(1), &:nth-of-type(2) {
                        margin-block-end: 3rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 590px) {
    footer {
        > div {
            > div {
                > div {
                    margin-block-end: 3rem;
                    &:last-child {
                        margin-block-end: 0rem;
                    }
                }
            }
        }
    }
}