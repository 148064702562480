@import "../../../styleFunctions/mixins";

.pointerDetail {
    padding-top: 0px;
    .detailBlock {
      @include flexContainer($gap: 2rem);
      flex-wrap: wrap;
      max-width: 80rem;
      margin: 5rem auto;
      flex-wrap: wrap;
      .detail {
        @include flexContainer;
        position: relative;
        flex: 1 0 30rem;
        width: 100%;
        padding-block: 1rem;
        > div {
          &:nth-of-type(1) {
            flex: 1 0 4.5rem;
          }
        }
        h3 {
          font-weight: 700;
          color: var(--primaryColor);
        }
        p {
          font-size: 1.4rem;
        }
      }
    }
  }