@import "../../../styleFunctions/mixins";

.stepPointerBlock {
    @include flexContainer;
    gap: 8rem !important;
    width: 100%;
    // position: absolute;
    // top: 2rem;
    span {
        width: 4rem;
        height: 4rem;
        background-color: var(--whiteColor);
        border: 0.3rem solid var(--primaryColor);
        color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 1.6rem;
        font-weight: 500;
        position: relative;
        &::after {
            content: "";
            width: 8.1rem;
            height: 0.6rem;
            border-block: 0.2rem solid var(--primaryColor);
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: -8.3rem;
        }
        &:first-child {
            &::after {
                display: none;
            }
        }
    }
    .active {
        background-color: var(--primaryColor);
        color: var(--whiteColor);
        &::after {
            background-color: var(--primaryColor);
        }
    }
}