@import "../../../../styleFunctions/mixins";

.confirmDeletionContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 9999999999;
  top: 0;
  left: 0;
  @include flexContainer;
  .confirmDeletionInner {
    width: 100%;
    max-width: 55rem;
    background-color: var(--whiteColor);
    overflow: hidden;
    padding: 40px 20px 0;
    border-top: 5px solid var(--primaryColor);
    > div {
      &:nth-of-type(1) {
        @include flexContainer(
          $justifyContent: flex-start,
          $alignItems: flex-start
        );
        > div {
          &:nth-of-type(1) {
            flex: 0 0 100px;
            img {
              max-width: 100px;
            }
          }
        }
      }
    }
    .confirmDeleteForm {
      margin-top: 20px;
      > div {
        &:nth-of-type(1) {
          @include flexContainer($gap: 5px);
          flex-direction: column;
          label,
          input {
            width: 100%;
          }
        }
      }
    }
    .confirmDeletionBody {
      h2 {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 12px;
      }
      .userDetailsBlock {
        @include flexContainer($alignItems: flex-start, $gap: 10px);
        flex-direction: column;
      }
    }
    .confirmDeletionBtn {
      @include flexContainer;
      padding: 2rem;
      border-top: 0.1rem solid var(--primaryColor);
      margin-top: 20px;
      button {
        font-size: 1.5rem;
        padding-inline: 2rem;
        &:nth-of-type(2) {
          background-color: transparent;
          color: var(--primaryColor);
          &:hover {
            background-color: var(--primaryColor);
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .confirmDeletionContainer {
    padding-inline: 2rem;
    .confirmDeletionInner {
      > div {
        &:nth-of-type(1) {
          flex-direction: column;
          align-items: center;
        }
      }
      .confirmDeletionBody {
        h2 {
          font-size: 20px;
        }
      }
      .confirmDeletionBtn {
        flex-direction: column;
        padding-block: 1.5rem;
        button {
          width: 100%;
        }
      }
    }
  }
}
