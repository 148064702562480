@import "../../../../styleFunctions/mixins";

.questionBlock {
  @include flexContainer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.2);
  .questionBox {
    @include flexContainer;
    flex-direction: column;
    width: 100%;
    max-width: 50rem;
    background-color: var(--whiteColor);
    border-radius: 2rem;
    padding: 4rem;
    > div {
      width: 100%;
      @include flexContainer;
      flex-direction: column;
      button {
        width: 100%;
        max-width: 80%;
        margin: 0 auto;
        padding-block: 1rem;
      }
    }
  }
}
