@import "../../styleFunctions/mixins";

.contactPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/contactBanner.jpg));
    min-height: 35rem;
  }
  .contactDetail {
    .detailBlock {
    @include flexContainer($gap: 0);
    flex-wrap: wrap;
      max-width: 60rem;
      margin: 5rem auto;
      flex-wrap: wrap;
      .detail {
        @include flexContainer;
        position: relative;
        flex: 1 0 20rem;
        width: 100%;
        padding-block: 2rem;
        h3 {
          font-weight: 700;
        }
        > a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 2;
            color: transparent !important;
        }
      }
    }
  }
  .contactForm {
    background-color: var(--secondaryGreyColor);
    > div {
        padding-block-end: 10rem;
    }
    .formBlock {
        max-width: 80rem;
        margin: 5rem auto 0;
        position: relative;
        .loaderBox {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgb(255,255,255,0.8);
          @include flexContainer($gap: 0);
          z-index: 3;
          top: 0;
          left: 0;
          border-radius: 1.1rem;
        }
        .successBox {
          @include flexContainer;
          flex-direction: column;
          img {
            max-width: 25rem;
          }
          h3 {
            text-align: center;
            font-weight: 600;
            font-size: 2.2rem;
            line-height: 3.2rem;
          }
        }
        form {
          @include flexContainer($gap: 30px);
          flex-direction: column;
          > div {
            width: 100%;
          }
            .fieldContainer {
                @include flexContainer($gap: 30px);
                width: 100%;
                .formSubjectField {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                }
                > div {
                  width: 100%;
                  flex: 1 0 48%;
                  position: relative;
                  > label {
                    font-size: 2rem;
                    font-weight: 600;
                    // margin-bottom: 0.5rem;
                  }
                  .errorText {
                    position: absolute;
                    width: 100%;
                    bottom: -34px;
                    left: 0;
                  }
                }
                input, textarea, select {
                    // margin-bottom: 3rem;
                    width: 100%;
                    // flex: 1 0 48%;
                    border: 2px solid var(--whiteColor);
                    padding: 1rem 1.5rem;
                    border-radius: 0.8rem;
                    font-size: 1.7rem;
                    &:focus {
                        outline: none;
                    }
                }
                > button {
                  margin-top: 2rem;
                }
            }
        }
    }
  }
  .faqSection {
    > div {
      padding-bottom: 10rem;
    }
    .faqCol {
      @include flexContainer;
      flex-direction: column;
      > h2 {
        width: 100%;
      }
      ul {
        @include flexContainer;
        width: 100%;
      flex-direction: column;
      }
    }
  }
}


@media only screen and (max-width: 590px) {
    .contactPage {
        .contactDetail {
            .detailBlock {
                .detail {
                    justify-content: flex-start;
                }
            }
        }
    }
}
