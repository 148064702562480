@import "./styleFunctions/mixins";

main {
  @include flexContainer(
    $alignItems: flex-start,
    $justifyContent: flex-start,
    $gap: 0
  );
  flex-direction: column;
  min-height: 100vh;
  header,
  footer {
    width: 100%;
  }
  > div {
    flex: 1 1 auto;
    width: 100%;
  }
}

.sectionContainer {
  padding-block-start: 10rem;
  > div {
    padding-inline: 2rem;
  }
}

.promotionSlider {
  .slick-active {
    > div {
      > div {
        img {
          filter: drop-shadow(-6px 1px 12px rgb(0, 0, 0, 0.3));
        }
        &:after {
          display: none !important;
        }
      }
    }
  }
  .slick-arrow {
    width: 5rem;
    height: 7rem;
    z-index: 99;
  }
  .slick-prev {
    left: 11.4rem;
  }
  .slick-next {
    right: 11.4rem;
  }
}

.mobileMenuBlock {
  .active {
    border-bottom: 0.2rem solid var(--primaryColor);
  }
}

.map-container {
  height: 100%;
  width: 100%;
}

/* Checkbox Styling */

.material-checkbox {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: var(--secondaryColor);
  cursor: pointer;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    border: 2px solid var(--primaryColor);
    border-radius: 4px;
    transition: all 0.3s;
  }
  input[type="checkbox"]:checked ~ .checkmark {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
  }
  input[type="checkbox"]:checked ~ .checkmark:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  input[type="checkbox"]:focus ~ .checkmark {
    box-shadow: 0 0 0 2px var(--primaryColor);
  }
  input[type="checkbox"] ~ .checkmark {
    border-color: var(--primaryColor);
  }
  input[type="checkbox"]:disabled ~ .checkmark {
    opacity: 0.5;
    cursor: not-allowed;
  }
  input[type="checkbox"]:disabled ~ .checkmark:hover {
    border-color: #4d4d4d;
  }
}

/* Loader Styling */

.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: var(--primaryColor);
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
}

.three-body__dot:after {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
}

.three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15)
    ease-in-out;
}

.three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

.disabledBtn {
  pointer-events: none;
  background-color: var(--primaryGreyColor) !important;
  border: none !important;
}

.uploadLogoContainer {
  width: 100%;
  .uploadContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-inline-start: 1.5rem;
    justify-content: space-between;
    width: 100%;
    border: 0.2rem solid var(--primaryColor);
    border-radius: 0.5rem;
    font-size: 1.8rem;
    color: var(--secondaryColor);
    label {
      background-color: var(--primaryColor);
      font-weight: 600;
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      color: var(--whiteColor);
      cursor: pointer;
      padding: 10px 10px;
    }

    #choosenFile {
      margin-left: 0.3rem;
    }
  }
}

.uploadVendorFile {
  position: relative;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}

/** Popup BG Layer ***/

.popupBgLayer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
}

.cardSlider {
  width: 100%;
  margin: 0 auto;
  .cardBox {
    padding: 2rem;
    img {
      border-radius: 1.5rem;
      filter: drop-shadow(2px 4px 6px rgb(0, 0, 0, 0.3));
    }
  }
  .slick-arrow {
    // background-color: var(--primaryColor) !important;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    &::before {
      display: none;
    }
    svg {
      color: var(--whiteColor);
    }
  }
  .slick-prev {
    left: -75px;
  }
  .slick-next {
    right: -75px;
    z-index: 3;
  }
}

.floatingBtn {
  position: fixed;
  right: -46px;
  bottom: 30%;
  background-color: var(--tertiaryColor);
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  padding: 8px 12px;
  transform: rotate(-90deg);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .floatingBtn {
    display: none;
  }
}
