@import "../../styleFunctions/mixins";

.loginPage {
  @include flexContainer;
  flex-wrap: wrap;
  > div {
    // flex: 1 0 48%;
    min-height: 60rem;
    &:nth-of-type(1) {
      @include flexContainer($gap: 0);
      flex: 1 0 28%;
      > div {
        margin-right: -37rem;
        z-index: 2;
      }
    }
    &:nth-of-type(2) {
      flex: 1 0 68%;
      @include backgroundImage($image: url(../../assets/aboutSliderOne.png));
    }
  }
  .activeProfile {
    > div {
      // margin-right: -37rem;
    }
  }
}

@media only screen and (max-width: 990px) {
    .loginPage {
        > div {
            &:nth-of-type(1) {
                padding-inline: 2rem;
                > div {
                    margin-right: 0;
                }
            }
        }
    }
} 
