@import "../../../../styleFunctions/mixins";

.becomeFleetMemberForm {
  > div {
    @include flexContainer($gap: 2rem);
    width: 100%;
    > div {
      @include flexContainer($gap: 0.5rem);
      flex-direction: column;
      width: 100%;
      position: relative;
      .errorText {
        position: absolute;
        bottom: -3.5rem;
        left: 0;
      }
    }
  }
  .formBtnContainer {
    button {
      margin: 0 0 0 auto;
    }
  }
  .formTitle {
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin: 0;
  }
}
