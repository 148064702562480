.termsConditionPage {
  h2,
  h3,
  p {
    margin: 0;
  }
  h3 {
    font-weight: 600;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.6rem;
  }
  > div {
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
  .pageTitle {
    h2 {
      position: relative;
      &:after {
        content: "";
        background-color: var(--primaryColor);
        width: 10rem;
        height: 0.4rem;
        position: absolute;
        bottom: -2rem;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
  .personalInfo {
    margin-top: 3rem;
  }
  .infoList {
    padding-left: 2rem !important;
    list-style: disc !important;
    margin-top: 2rem !important;
  }
}
