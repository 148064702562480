@import "../../../styleFunctions/mixins";

.tableBlock {
  .tableFilter {
    @include flexContainer($justifyContent: space-between);
    margin-bottom: 3rem;
    > div {
      @include flexContainer($justifyContent: flex-start);
      &:nth-of-type(2) {
        width: 100%;
        max-width: 300px;
      }
      p {
        margin: 0;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input {
        background: var(--whiteColor);
        border: 0.1rem solid var(--primaryColor);
        height: 3rem;
        padding-left: 1rem;
        font-size: 1.4rem;
        border-radius: 0.5rem;
        width: 100%;
        &:focus {
          outline: none;
        }
        &[type="number"] {
          caret-color: transparent;
        }
      }
    }
    .displayData {
      gap: 1rem;
      input {
        max-width: 5rem;
      }
    }
  }
  .locTable {
    table {
      table-layout: fixed;
      width: 100%;
      border: none;
      border-top: 0.3rem solid var(--primaryGreyColor);
      border-bottom: 0.3rem solid var(--primaryGreyColor);
      tbody {
        tr {
          td {
            padding: 2rem;
            .storeHours {
              button {
                margin: 0 auto;
                padding: 0.5rem 1.5rem;
                width: 100%;
                &:hover ~ div {
                  display: block;
                }
              }
            }
            &:nth-of-type(4),
            &:nth-of-type(5) {
              p {
                text-align: center;
              }
            }
            &:last-child {
              a {
                color: var(--primaryColor) !important;
                font-size: 1.6rem;
                font-weight: 500;
                border-bottom: 0.2rem solid var(--primaryColor);
                transition: all 0.3s ease-in-out;
                &:hover {
                  color: var(--secondaryColor) !important;
                  border-color: var(--secondaryColor) !important;
                }
              }
            }
          }
        }
      }
    }
    .tableAccord {
      display: none;
      flex-direction: column;
      gap: 4rem;
      > div {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .tableBlock {
    .locTable {
      table {
        table-layout: auto;
        tr {
          td {
            .storeHours {
              button {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .tableBlock {
    .tableFilter {
      display: none;
    }
    table {
      display: none;
    }
    .tableAccord {
      display: flex !important;
    }
  }
}
