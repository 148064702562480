@import "../../../styleFunctions/mixins";

.accordionBlock {
  border: 0.2rem solid var(--primaryColor);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  .accordionHeader {
    width: 100%;
    border: none;
    background-color: var(--whiteColor);
    padding: 1rem 1.5rem;
    @include flexContainer($gap: 0.5rem);
    transition: all 0.3s ease-in-out;
    > div {
      width: 100%;
      svg,
      p {
        color: var(--secondaryColor);
        transition: all 0.3s ease-in-out;
      }
      &:nth-of-type(1) {
        @include flexContainer($justifyContent: flex-start, $gap: 1rem);
        flex: 1 0 80%;
        p {
          margin: 0;
        }
      }
      &:nth-of-type(2) {
        text-align: right;
        flex: 1 0 15%;
      }
    }
  }
  .accordionBody {
    display: none;
    .hoursBlock {
        > div {
            position: unset;
            display: block;
            box-shadow: none;
        } 
    }
    a {
        color: var(--primaryColor) !important;
        font-size: 1.6rem !important;
        text-decoration: underline !important;
        font-weight: 500 !important;
    }
  }
  &:hover {
    .accordionHeader {
      background-color: var(--whiteColor);
      > div {
        svg,
        p {
          color: var(--primaryColor);
        }
      }
    }
  }
}

.active {
  background-color: var(--whiteColor);
  .accordionHeader {
    background-color: var(--whiteColor);
    p, svg {
        color: var(--primaryColor) !important;
    }
  }
  .accordionBody {
    display: block;
    padding: 1rem 1.5rem;
    h2 {
        font-size: 1.6rem;
        margin-top: 3rem;
        &:nth-of-type(1) {
            margin-top: 0;
        }
    }
  }
}
