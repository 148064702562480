@import "../../styleFunctions/mixins";

.cancoConnectPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/connectBanner.png));
    min-height: 60rem;
    position: relative;
    &:before {
      content: "";
      height: 4rem;
      width: 4rem;
      //   background-color: var(--primaryColor);
      border: 2rem solid var(--primaryColor);
      position: absolute;
      bottom: -1.8rem;
      right: calc(25% - 3rem);
      clip-path: polygon(23% 0, 100% 50%, 24% 100%, 0% 100%, 0 47%, 0% 0%);
    }
    &:after {
      content: "";
      width: 100%;
      background-color: var(--primaryColor);
      height: 4rem;
      bottom: -1.8rem;
      position: absolute;
      max-width: 75%;
    }
  }
  .aboutConnectSection {
    padding-bottom: 10rem;
    .aboutConnectInfo {
      h2 {
        text-align: center;
        font-size: 3rem;
      }
      .pointerSection {
        @include flexContainer($justifyContent: space-between);
        max-width: 75%;
        margin: 7rem auto;
        flex-wrap: wrap;
        .pointerInfo {
          @include flexContainer($gap: 1rem);
          flex: 1 0 10rem;
          flex-direction: column;
          img {
            max-width: 7rem;
          }
          p {
            margin: 0;
          }
        }
      }
      .cardImg {
        text-align: center;
        max-width: 70%;
        margin: 0 auto;
        transform: translate(20%, 0px);
      }
    }
  }
  .applySection {
    background-color: var(--secondaryGreyColor);
    > div {
      padding: 5rem 0;
      > div {
        > div {
          h2 {
            text-align: center;
            font-size: 3rem;
          }
          .contactInfo {
            @include flexContainer($justifyContent: space-between);
            max-width: 70rem;
            margin: 3rem auto 0;
            flex-wrap: wrap;
            > div {
              flex: 1;
              @include flexContainer;
              h2 {
                text-align: left;
                font-size: 2.5rem;
                margin-bottom: 0rem;
                line-height: 3.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .cancoConnectPage {
    .aboutConnectSection {
      .aboutConnectInfo {
        .pointerSection {
          align-items: flex-start;
          max-width: 100%;
          p {
            text-align: center;
          }
        }
        .cardImg {
          max-width: 100%;
        }
      }
    }
    .applySection {
      > div {
        > div {
          > div {
            .contactInfo {
              > div {
                justify-content: flex-start;
                margin-inline: 2rem;
              }
            }
          }
        }
      }
    }
  }
}
