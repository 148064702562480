@import "../../../../styleFunctions/mixins";

.balanceContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 9999999999;
  top: 0;
  @include flexContainer;
  .balanceInner {
    width: 100%;
    max-width: 45rem;
    background-color: var(--whiteColor);
    border-radius: 1.5rem;
    overflow: hidden;
    .balanceHeader {
      @include flexContainer($justifyContent: space-between);
      padding: 1rem 2rem;
      border-bottom: 0.1rem solid var(--primaryColor);
      p {
        margin: 0;
        font-weight: 500;
      }
      svg {
        transform: rotate(45deg);
        cursor: pointer;
      }
    }
    .balanceBody {
      padding: 2rem;
      form {
        @include flexContainer($gap: 3rem);
        flex-direction: column;
        > div {
          width: 100%;
          @include flexContainer($gap: 0.5rem);
          flex-direction: column;
          position: relative;
          label {
            font-size: 1.4rem;
            color: var(--secondaryColor);
            font-weight: 600;
          }
          input,
          select {
            height: 40px;
            font-size: 1.6rem;
            padding: 0 0 0 1rem;
            border-radius: 0.7rem;
            outline: none;
            border: 0.1rem solid #e5e5e5;
            filter: drop-shadow(0px 1px 0px #efefef)
              drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
            transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
            &:focus {
              border: 1px solid transparent;
              box-shadow: 0px 0px 0px 2px var(--primaryColor);
              background-color: transparent;
            }
          }
          label,
          input,
          select {
            width: 100%;
          }
          .errorText {
            position: absolute;
            bottom: -3.5rem;
            left: 0;
          }
        }
        button {
          width: 100%;
          padding: 1rem 0;
          svg {
            color: var(--whiteColor);
          }
        }
      }
    }
  }
}
