@import "../../styleFunctions/mixins";

.roadTripPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/rtBanner.jpg));
    min-height: 45rem;
  }
  .rulesContent {
    padding-bottom: 5rem;
    > div {
      > div {
        &:nth-of-type(1) {
          margin-bottom: 60px;
          h2 {
            @include flexContainer;
            img {
              width: 100px;
            }
          }
        }
        > div {
          ol,
          ul {
            @include flexContainer;
            flex-direction: column;
            > li {
              width: 100%;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .howDoesItWork {
    padding-bottom: 6rem;
    padding-top: 0;
    > div {
      &:nth-of-type(1) {
        margin-bottom: 60px;
        > div {
          p {
            font-size: 18px;
            font-weight: 400;
            max-width: 1060px;
            margin: 20px auto 0;
          }
        }
      }
      > div {
        .stopList {
          @include flexContainer($gap: 20px);
          flex-direction: column;
          > img {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
          }
        }
        .grandPrize {
          margin-top: 50px;
          text-align: center;
          > img {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
          }
        }
        ol,
        ul {
          @include flexContainer;
          flex-direction: column;
          > li {
            width: 100%;
            font-size: 18px;
            @include flexContainer($justifyContent: flex-start);
            img {
              width: 100px;
            }
          }
        }
        p {
          @include flexContainer($gap: 10px);
          margin-top: 50px;
          color: var(--tertiaryColor);
          font-weight: 600;
          font-size: 22px;
          svg {
            font-size: 30px;
          }
        }
      }
    }
  }
  .downloadApp {
    background-color: var(--tertiaryColor);
    padding-block: 50px;
    > div {
      > div {
        > div {
          h2 {
            color: var(--whiteColor);
            text-align: center;
          }
          .downloadAppLink {
            @include flexContainer;
            margin-block-start: 3rem;
            a {
              display: block;
              width: 100%;
              max-width: 20rem;
              img {
                height: 6.2rem;
                object-fit: cover;
                border-radius: 1rem;
                border: 1px solid gray;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .roadTripPage {
    .bannerSection {
      min-height: 20rem;
    }
    .rulesContent {
      > div {
        > div {
          &:nth-of-type(1) {
            h2 {
              flex-direction: column;
              img {
                width: 150px;
              }
            }
          }
        }
      }
    }
    .howDoesItWork {
      > div {
        > div {
          p {
            font-size: 18px;
            svg {
              width: 50px;
            }
          }
        }
      }
    }
    .downloadApp {
      > div {
        > div {
          > div {
            .downloadAppLink {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
