@import "../../styleFunctions/mixins";

.workWithUsPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/workBanner.png));
    min-height: 45rem;
  }
  .secondSection {
    padding-bottom: 10rem;
    .careerBlock {
      // max-width: 100rem;
      margin: 6rem auto 0;
    }
    .secondCol {
      @include flexContainer($justifyContent: flex-start);
      flex-direction: column;
      .jobHeader {
        text-align: center;
        position: relative;
        img {
          max-width: 10rem;
          aspect-ratio: 1;
          object-fit: contain;
          margin-bottom: 2rem;
        }
        h2 {
          font-size: 2rem;
          line-height: 3rem;
        }
        &:after {
          content: "";
          position: absolute;
          background-color: var(--primaryGreyColor);
          width: 30rem;
          height: 0.1rem;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      > p {
        margin-top: 2rem;
        max-width: 40rem;
        margin: 0 auto;
        text-align: center;
      }
      a {
        width: 100%;
        max-width: 27rem;
      }
      &:nth-of-type(2), &:nth-of-type(1) {
        a {
          margin-top: 2.5rem;
        }
      }
    }
  }
  .whyCancoSection {
    background-color: var(--secondaryGreyColor);
    padding: 6rem 0;
    > div {
      padding-top: 0rem;
      > div {
        > div {
          @include flexContainer;
          flex-direction: column;
        }
      }
    }
  }
  .contactDetail {
    .detailBlock {
      @include flexContainer($gap: 4rem);
      flex-wrap: wrap;
      max-width: 80rem;
      margin: 5rem auto;
      flex-wrap: wrap;
      .detail {
        @include flexContainer;
        position: relative;
        flex: 1 0 25rem;
        width: 100%;
        padding-block: 2rem;
        > div {
          &:nth-of-type(1) {
            flex: 1 0 4.5rem;
          }
        }
        h3 {
          font-weight: 700;
          color: var(--primaryColor);
        }
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .workWithUsPage {
    // .secondSection {
    //   h2 {
    //     padding: 0 2rem;
    //   }
    //   .careerBlock {
    //     .secondCol {
    //       &:nth-of-type(1) {
    //         margin-bottom: 4rem;
    //       }
    //     }
    //   }
    // }
    .whyCancoSection {
      > div {
        padding-top: 0rem;
      }
    }
    .contactDetail {
      .detailBlock {
        margin: 0px;
        gap: 3rem;
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .workWithUsPage {
    .secondSection {
      h2 {
        padding: 0 2rem;
      }
      .careerBlock {
        .secondCol {
          &:nth-of-type(1), &:nth-of-type(2) {
            margin-bottom: 4rem;
          }
        }
      }
    }
    .whyCancoSection {
      > div {
        padding-top: 0rem;
      }
    }
    .contactDetail {
      .detailBlock {
        margin: 0px;
        gap: 0px;
      }
    }
  }
}
