.findCancoSection {
    padding-block-end: 10rem;
    h2 {
      margin: 0;
    }
    .sectionImg {
      text-align: center;
      margin-bottom: 2rem;
      img {
        max-width: 6rem;
      }
    }
    .descriptionBlock {
      max-width: 70%;
      margin: 0 auto;
      p {
        margin: 2.5rem 0;
      }
    }
  }

@media only screen and (max-width: 990px) {
  .findCancoSection {
    .secondInnerTwo {
      .aboutDescription {
        margin-block-start: 6rem;
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .findCancoSection {
    .descriptionBlock {
      max-width: 100%;
    }
  }
}