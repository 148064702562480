.toolTipContainer {
  position: relative;
  .toolTipBox {
    background-color: var(--secondaryColor);
    padding: 0.7rem;
    color: var(--whiteColor);
    border-radius: 0.3rem;
    box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2);
    position: absolute;
    width: 29rem;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    display: none;
    transition: all 0.2s ease-in-out;
    z-index: 1;
  }
  svg:hover ~ .toolTipBox {
    display: inline;
  }
}

@media only screen and (max-width: 600px) {
    .toolTipContainer {
        .toolTipBox {
            width: 20rem;
        }
    }
}
