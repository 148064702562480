@import "../../../../styleFunctions/mixins";

.confirmDeletionContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 9999999999;
  top: 0;
  left: 0;
  @include flexContainer;
  .confirmDeletionInner {
    width: 100%;
    max-width: 55rem;
    background-color: var(--whiteColor);
    overflow: hidden;
    padding: 40px 20px 40px;
    border-top: 5px solid var(--primaryColor);
    .successBox {
      @include flexContainer;
      flex-direction: column;
      img {
        max-width: 10rem;
      }
      h3 {
        text-align: center;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 3.2rem;
      }
      button {
        // color: var(--primaryColor) !important;
        font-size: 1.8rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .confirmDeletionContainer {
    padding-inline: 2rem;
    .confirmDeletionInner {
      > div {
        &:nth-of-type(1) {
          flex-direction: column;
          align-items: center;
        }
      }
      .confirmDeletionBody {
        h2 {
          font-size: 20px;
        }
      }
      .confirmDeletionBtn {
        flex-direction: column;
        padding-block: 1.5rem;
        button {
          width: 100%;
        }
      }
    }
  }
}
