@import "../../styleFunctions/mixins";

.fleetCardPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/bannerXpress.png));
    min-height: 60rem;
    position: relative;
    &:before {
      content: "";
      height: 4rem;
      width: 4rem;
      //   background-color: var(--primaryColor);
      border: 2rem solid var(--primaryColor);
      position: absolute;
      bottom: -1.8rem;
      right: calc(25% - 3rem);
      clip-path: polygon(23% 0, 100% 50%, 24% 100%, 0% 100%, 0 47%, 0% 0%);
    }
    &:after {
      content: "";
      width: 100%;
      background-color: var(--primaryColor);
      height: 4rem;
      bottom: -1.8rem;
      position: absolute;
      max-width: 75%;
    }
  }
  .benefitsFleetCard {
    padding-bottom: 10rem;
    .benefitsTitle {
      h2 {
        text-align: center;
        font-size: 3.4rem;
      }
      margin-bottom: 5rem;
    }
    .benefitsContent {
      align-items: center;
      .benefitInfo {
        @include flexContainer($gap: 4rem);
        flex-direction: column;
        flex-wrap: wrap;
        > h2 {
          text-align: left;
          margin: 0;
          font-size: 2.8rem;
          width: 100%;
        }
        .benefitBlock {
          @include flexContainer($gap: 4rem, $justifyContent: flex-start);
          width: 100%;
          > div {
            &:nth-of-type(1) {
              flex: 1 0 7rem;
            }
            &:nth-of-type(2) {
              flex: 1 0 50rem;
            }
          }
          img {
            max-width: 7rem;
          }
          ul {
            list-style: disc;
            li {
              font-size: 1.6rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .applyTodaySection {
    background-color: var(--secondaryGreyColor);
    
    > div {
      padding: 5rem 0;
      > div {
        text-align: center;
        > div {
          > a {
            max-width: fit-content;
            margin: 0 auto;
          }
          h2 {
            font-size: 2.8rem;
            margin: 0;
            text-align: center;
          }
          .applySteps {
            margin-block: 5rem;
            @include flexContainer(
              $justifyContent: space-between,
              $alignItems: flex-start
            );
            flex-wrap: wrap;
            max-width: 80rem;
            margin-inline: auto;
            .stepInfo {
              @include flexContainer($gap: 1rem);
              flex-direction: column;
              flex: 1;
              position: relative;
              z-index: 1;
              h3 {
                font-size: 2.2rem;
                color: var(--primaryColor);
                margin: 0;
                line-height: normal;
                border: 0.3rem solid var(--primaryColor);
                border-radius: 50%;
                padding: 0.8rem 1.5rem;
                aspect-ratio: 1;
              }
              p {
                font-size: 1.6rem;
                margin: 0;
                text-align: center;
                a {
                  color: var(--primaryColor) !important;
                  font-weight: 600;
                }
              }
              &:after {
                content: "";
                width: calc(100% - 20px);
                height: 0.2rem;
                background-color: var(--primaryColor);
                position: absolute;
                top: 21px;
                right: -50%;
                z-index: 0;
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .applicationSendInfo {
    padding-block: 4rem;
    padding-inline: 2rem;
    text-align: center;
  }
}

@media only screen and (max-width: 550px) {
  .fleetCardPage {
    .benefitsFleetCard {
      .benefitsContent {
        .benefitInfo {
          .benefitBlock {
            > div {
              &:nth-of-type(2) {
                flex: unset;
              }
            }
          }
        }
      }
    }
    .applyTodaySection {
      > div {
        > div {
          > div {
            .applySteps {
              .stepInfo {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
