@import "../../../../styleFunctions/mixins";

.signupForm {
  @include flexContainer($gap: 3rem);
  flex-direction: column;
  width: 100%;
  //   max-width: 80%;
  padding: 50px 25px 30px;
  background-color: #ffffff;
  box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  > div {
    @include flexContainer($gap: 4rem);
    width: 100%;
    &:last-of-type {
      flex-direction: column;
      > div {
        flex-direction: row;
        justify-content: flex-start;
        label {
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          span {
            flex: 1 0 20px;
          }
        }
        input {
          margin-top: 0.3rem;
          height: auto;
        }
        label,
        input {
          width: auto;
        }
      }
    }
    > div {
      @include flexContainer($gap: 0.5rem);
      flex-direction: column;
      width: 100%;
      position: relative;
      .innerLabelLink {
        color: var(--primaryColor) !important;
      }
      label {
        display: inline-block;
        width: 100%;
        font-size: 1.6rem;
        color: #8b8e98;
        font-weight: 600;
      }
      input {
        width: 100%;
        height: 40px;
        font-size: 1.6rem;
        padding: 0 0 0 1rem;
        border-radius: 0.7rem;
        outline: none;
        border: 0.1rem solid #e5e5e5;
        filter: drop-shadow(0px 1px 0px #efefef)
          drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
        transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
        &:focus {
          border: 1px solid transparent;
          box-shadow: 0px 0px 0px 2px var(--primaryColor);
          background-color: transparent;
        }
      }
      .errorText {
        position: absolute;
        bottom: -3.5rem;
        left: 0;
      }
    }
  }
  button {
    width: 100%;
    max-width: 60%;
  }
}

.initialQues {
  @include flexContainer($gap: 3rem);
  flex-direction: column;
  width: 100%;
  padding: 50px 40px 30px;
  background-color: #ffffff;
  box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  h2 {
    font-size: 2.2rem;
    text-align: center;
  }
  > div {
    width: 100%;
    max-width: 70%;
    button {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 590px) {
  .signupForm {
    > div {
      flex-direction: column;
    }
  }
}
