@import "../../../../styleFunctions/mixins";

.schoolSpiritForm {
  > div {
    > div {
      justify-content: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
        > div {
          justify-content: center !important;
        }
      }
      > div {
        &:nth-of-type(1) {
          @include flexContainer($gap: 10px, $justifyContent: flex-end);
          svg {
            cursor: pointer;
          }
          .toolTipContainer {
            position: relative;
            .toolTipBox {
              background-color: var(--secondaryColor);
              padding: 0.7rem;
              color: var(--whiteColor);
              border-radius: 0.3rem;
              box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2);
              position: absolute;
              width: 29rem;
              top: 50%;
              transform: translateY(-50%);
              left: -290px;
              display: none;
              transition: all 0.2s ease-in-out;
              z-index: 1;
              font-size: 11px;
            }
            svg:hover ~ .toolTipBox {
              display: inline;
            }
          }

          @media only screen and (max-width: 600px) {
            .toolTipContainer {
              .toolTipBox {
                width: 20rem;
              }
            }
          }
        }
        padding-bottom: 20px;
        label {
          text-align: right;
          color: var(--secondaryColor);
          width: auto;
        }
        button {
          background-color: var(--tertiaryColor);
          border-color: var(--tertiaryColor);
          width: 100%;
          max-width: 200px;
          margin-top: 20px;
          &:hover {
            color: var(--tertiaryColor);
          }
        }
        .requireNote {
          display: block;
          text-align: right;
          font-weight: 600;
        }
        .cityprovinceData {
          @include flexContainer;
          > div {
            width: 100%;
            position: relative;
            padding-bottom: 20px;
          }
        }
        position: relative;
        .errorText {
          position: absolute;
          bottom: -3px;
          margin: 0;
          text-align: left !important;
        }
      }
    }
    .locationData {
      > div {
        &:nth-of-type(2) {
          padding-bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .schoolSpiritForm {
    > div {
      > div {
        > div {
          &:nth-of-type(1) {
            justify-content: flex-start;
            padding-bottom: 0px;
          }
          label {
            text-align: left;
            margin-bottom: 10px;
          }
          .cityprovinceData {
            flex-direction: column;
          }
        }
      }
      .locationData {
        > div {
          padding-bottom: 0px;
        }
      }
    }
  }
}
