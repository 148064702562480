@import "../../styleFunctions/mixins";

.aboutPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/aboutUsBanner.png));
    min-height: 45rem;
  }
  .aboutPr {
    .prLogoContainer {
      @include flexContainer($justifyContent: space-between);
      flex-direction: column;
      position: relative;
      > div {
        &:nth-of-type(1) {
          &:after {
            content: "";
            width: 2px;
            height: 350px;
            background-color: var(--secondaryColor);
            position: absolute;
            top: 309px;
            right: 50%;
            transform: translate(-50%, 0);
          }
        }
        &:nth-of-type(2) {
          img {
            max-width: 30rem;
          }
        }
      }
    }
  }
  .timelineContainer {
    padding-top: 10rem;
  }
  .cancoCareSection {
    @include backgroundImage($image: url(../../assets/caresStrip.png));
    .cancoCareInner {
      padding: 5rem 0;
      h2 {
        font-size: 2.8rem;
        color: var(--whiteColor);
        margin: 0;
      }
      h3 {
        font-size: 2.8rem;
        color: var(--whiteColor);
        margin: 0;
        font-weight: 700;
      }
      p {
        color: var(--whiteColor);
        margin-top: 3rem;
      }
    }
  }
  .secondSection {
    padding-bottom: 10rem;
    h2 {
      margin: 0;
    }
    .sectionImg {
      text-align: center;
      margin-bottom: 2rem;
      img {
        max-width: 6rem;
      }
    }
    .secDescriptionBlock {
      max-width: 70%;
      margin: 0 auto;
      p {
        margin: 2.5rem 0;
      }
    }
    .secondInnerTwo {
      .aboutSliderContainer {
        .aboutImgContainer {
          border-radius: 2rem;
          img {
            border-radius: 2rem;
          }
        }
      }
      .aboutDescription {
        padding-inline-start: 3.5rem;
        overflow: hidden;
        p {
          padding-block: 1.5rem;
        }
      }
    }
  }
  .blogSection {
    background-color: #e8e8e8;
    .blogPageInner {
      padding-bottom: 10rem;
      .blogSectionTitle {
        margin-bottom: 3rem;
        h2 {
          margin: 0;
          position: relative;
          &:after {
            content: "";
            width: 10rem;
            height: 0.2rem;
            background-color: var(--primaryColor);
            position: absolute;
            bottom: -1.5rem;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
      > div {
        > div {
          padding: 2rem;
        }
      }
      .seeMoreBlog {
        margin-top: 3rem;
        a {
          max-width: 20rem;
          margin: 0 auto;
        }
      }
    }
  }
}
