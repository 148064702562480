.starRating {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.starBtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 28px;
}
.on {
  color: #ffc107;
}
.off {
  color: #ccc;
}
