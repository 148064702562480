@import "../../styleFunctions/mixins";

.pageContainer {
  .secondSection {
    padding-top: 0;
    padding-block-end: 10rem;
    h2 {
      margin: 0;
    }
    .sectionImg {
      text-align: center;
      margin-bottom: 2rem;
      img {
        max-width: 6rem;
      }
    }
    .secDescriptionBlock {
      max-width: 70%;
      margin: 0 auto;
      p {
        margin: 2.5rem 0;
      }
    }
    .secondInnerTwo {
      .aboutSliderContainer {
        .aboutImgContainer {
          border-radius: 2rem;
          img {
            border-radius: 2rem;
          }
        }
      }
      .aboutDescription {
        padding-inline-start: 3.5rem;
        overflow: hidden;
        p {
          padding-block: 1.5rem;
        }
        a {
          max-width: fit-content;
        }
      }
    }
  }
  .roadTripSection {
    @include backgroundImage($image: url(../../assets/roadTripBg.jpg));
    @include flexContainer;
    min-height: 40rem;
    padding-block-start: 0rem;
    margin-top: -4px;
    .thirdSectionRow {
      justify-content: flex-end;
      padding-inline: 2rem;
      overflow: hidden;
      > div {
        &:nth-of-type(1) {
          text-align: center;
          // img {
          //   width: 100%;
          //   max-width: 200px;
          // }
        }
      }
      .thirdDescription {
        padding-inline-start: 3.5rem;
        a {
          margin-block-start: 2rem;
          max-width: fit-content;
        }
      }
    }
  }
  .thirdSection {
    @include backgroundImage($image: url(../../assets/homeThirdSectonBg.png));
    @include flexContainer;
    min-height: 40rem;
    padding-block-start: 0rem;
    .thirdSectionRow {
      justify-content: flex-end;
      padding-inline: 2rem;
      overflow: hidden;
      .thirdDescription {
        padding-inline-start: 3.5rem;
        a {
          margin-block-start: 2rem;
          max-width: fit-content;
        }
      }
    }
  }
  .fourthSection {
    padding-block-end: 10rem;
    .fourthDescription {
      p {
        margin-block: 2rem;
      }
    }
    .fourthImg {
      text-align: center;
      overflow: hidden;
      img {
        max-width: 40rem;
        filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.5));
      }
    }
  }
  .fifthSection {
    text-align: center;
    .sectionImg {
      margin-bottom: 2.5rem;
      img {
        max-width: 30rem;
      }
    }
    h2 {
      margin-block-end: 5rem;
      padding-inline: 2rem;
    }
    .promoImgContainer {
      position: relative;
      img {
        padding: 2rem;
      }
      &:after {
        content: "";
        width: calc(100% - 4rem);
        height: 100%;
        background-color: #002c747d;
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .sixthSection {
    padding-block-end: 10rem;
    .sixthSectionCol {
      @include flexContainer($justifyContent: flex-start, $gap: 0);
      overflow: hidden;
      > div {
        &:nth-of-type(1) {
          z-index: 1;
          img {
            max-width: 50rem;
          }
        }
        &:nth-of-type(2) {
          background-color: var(--primaryColor);
          padding: 2rem 7rem 2rem 12rem;
          border-radius: 2.1rem;
          margin-inline-start: -6rem;
          z-index: 0;
          h2 {
            margin-bottom: 2rem;
          }
        }
        .downloadApp {
          @include flexContainer($justifyContent: flex-start);
          margin-block-start: 1rem;
          a {
            display: block;
            width: 100%;
            max-width: 20rem;
            img {
              min-height: 6.2rem;
              object-fit: cover;
              border-radius: 1rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .pageContainer {
    .secondSection {
      .secondInnerTwo {
        .aboutDescription {
          margin-block-start: 6rem;
          padding: 0;
        }
      }
    }
    .thirdSection {
      .thirdSectionRow {
        padding-inline: 2rem;
        .thirdDescription {
          padding: 0px;
        }
      }
    }
    .roadTripSection {
      .thirdSectionRow {
        padding: 2rem;
        .thirdDescription {
          padding: 0px;
          margin-top: 20px;
          a {
            font-size: 16px;
          }
        }
      }
    }
    .fourthSection {
      .fourthImg {
        margin-block-start: 3rem;
      }
    }
    .sixthSection {
      .sixthSectionCol {
        > div {
          &:nth-of-type(1) {
            img {
              max-width: 80rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .pageContainer {
    .secondSection {
      .secDescriptionBlock {
        max-width: 100%;
      }
    }
    .sixthSection {
      .sixthSectionCol {
        flex-direction: column;
        > div {
          &:nth-of-type(2) {
            margin-inline-start: 0rem;
            padding: 4rem;
            margin-block-start: 3rem;
          }
        }
      }
    }
  }
  .searchContainer {
    max-width: 100%;
  }
}
