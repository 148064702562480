@import "../../../styleFunctions/mixins";

.blogCard {
  @include flexContainer($justifyContent: "flex-start");
  flex-direction: column;
  border-radius: 3rem;
  background: #e0e0e0;
  box-shadow: 2rem 2rem 6rem #bebebe, -2rem -2rem 6rem #ffffff;
  overflow: hidden;
  padding: 3rem;
  height: 100%;
  position: relative;
  &::before {
    content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(-45deg, #5f85d4 0%, var(--tertiaryColor) 100% );
  z-index: 0;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  }
  &:hover {
    &:before {
        height: 100%;
    }
    .blogImage {
        z-index: 1;
    }
    .blogContent {
        h2, p {
            color: var(--whiteColor);
        }
        a {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            border-color: var(--whiteColor);
            background-color: var(--whiteColor);
            color: var(--secondaryColor) !important;
        }
    }
  } 
  .blogImage {
    min-height: 20rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: 2rem;
  }
  .blogContent {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 8rem;
    h2 {
        font-size: 2.2rem;
        line-height: 3.2rem;
        margin: 0 0 2rem;
    }
    p {
        margin: 0
    }
    a {
        position: absolute;
        width: auto;
        bottom: 0;
        right: 0;
    }
  }
}
