@import '../../styleFunctions/mixins';

.forgotPasswordPage {
  @include flexContainer;
  min-height: 50rem;
  .emailBox {
    @include flexContainer;
    flex-direction: column;
    .emailHeader {
      @include flexContainer;
    }
    h2 {
      font-size: 2.2rem;
      line-height: 3.2rem;
      margin: 0;
    }
    input {
      width: 100%;
      border: 2px solid var(--primaryColor);
      padding: 1rem 1.5rem;
      border-radius: 0.8rem;
      font-size: 1.7rem;
      &:focus {
        outline: none;
      }
    }
    button {
      margin: 0 auto;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .forgotPasswordPage {
    gap: 0;
    .emailBox {
      max-width: 80%;
    }
  }
}
