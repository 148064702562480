@import '../../styleFunctions/mixins';

.findLocationPage {
  > div {
    padding-block-start: 6rem;
    > div {
      padding-block-end: 10rem;
      h2 {
        margin: 0;
      }
      .sectionImg {
        text-align: center;
        margin-bottom: 2rem;
        img {
          max-width: 6rem;
        }
      }
      .secDescriptionBlock {
        max-width: 70%;
        margin: 0 auto;
        p {
          margin: 2.5rem 0;
        }
      }
      .secondInnerTwo {
        margin-block-start: 10rem;
        .aboutSliderContainer {
          .aboutImgContainer {
            border-radius: 2rem;
            img {
              border-radius: 2rem;
            }
          }
        }
        .aboutDescription {
          padding-inline-start: 3.5rem;
          p {
            padding-block: 1.5rem;
          }
        }
      }
      .mapFilterSection {
        .filterLocation {
            @include flexContainer($justifyContent: space-between);
            margin-bottom: 3rem;
        }
        .locationTable {
          margin-top: 4rem;
        }
        .mapLoaderContainer, .mapError {
          @include flexContainer($gap: 0);
          height: 60rem;
        }
        .mapError {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .findLocationPage {
    > div {
      > div {
        .mapFilterSection {
          .filterLocation {
            flex-wrap: wrap;
            > div {
              flex: 1 0 22%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .findLocationPage {
    > div {
      > div {
        .secDescriptionBlock {
          max-width: 100%;
        }
        .mapFilterSection {
          .filterLocation {
            > div {
              flex: 1 0 46%;
            }
          }
        }
      }
    }
  }
}
