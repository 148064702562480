.blogPage {
  background-color: #e8e8e8;
  .blogPageInner {
    padding-bottom: 10rem;
    .blogPageTitle {
      margin-bottom: 3rem;
      h2 {
        text-align: center;
        position: relative;
        &:after {
          content: "";
          width: 10rem;
          height: 0.2rem;
          background-color: var(--primaryColor);
          position: absolute;
          bottom: -1.5rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
    > div {
      > div {
        padding: 2rem;
      }
    }
  }
}

.blogInnerPage {
  > div {
    padding-bottom: 10rem;
    .singleBlogImg {
      margin-bottom: 3rem;
      text-align: center;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .singleBlogContent {
      padding-left: 2rem;
      h2 {
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.6rem !important;
        font-family: var(--primaryFont) !important;
        > span {
          font-size: inherit !important;
          font-family: inherit !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .blogPage {
    .blogPageInner {
      > div {
        > div {
          padding: 0 0 4rem;
        }
      }
    }
  }
}
