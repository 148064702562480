.profileCard {
  --width: 40rem;
  --height: 50rem;
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: 14px;
  z-index: 1111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  .profileBg {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(var(--width) - 1rem);
    height: calc(var(--height) - 1rem);
    z-index: 2;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 2px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    p,
    h2 {
      margin: 0;
      text-align: center;
    }
    img {
      max-width: 18rem;
    }
    .editProfileBtn {
      position: absolute;
      top: 6px;
      right: 6px;
      border-radius: 50%;
      padding: 1rem;
    }
  }
  .profileBlob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: calc(var(--width) - 10rem);
    height: calc(var(--width) - 10rem);
    border-radius: 50%;
    background-color: var(--secondaryColor);
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
  }
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}
