@import "../../styleFunctions/mixins";

.registerPage {
  @include flexContainer($gap: 0);
  flex-wrap: wrap;
  .registerCol {
    min-height: 100rem;
    &:nth-of-type(1) {
      @include flexContainer($gap: 0);
      flex: 1 0 32%;
      > form {
        margin-right: -37rem;
        z-index: 2;
        max-width: 55rem;
      }
      > div {
        margin-right: -37rem;
        z-index: 2;
      }
    }
    &:nth-of-type(2) {
      flex: 1 0 64%;
      @include backgroundImage($image: url(../../assets/aboutSliderOne.png));
    }
  }
}

@media only screen and (max-width: 990px) {
  .registerPage {
    flex-direction: column;
    .registerCol {
      > div {
        margin-right: 0 !important;
      }
      &:nth-of-type(1) {
        padding: 4rem 2rem;
        min-height: unset;
        form {
          margin-right: 0;
        }
      }
      &:nth-of-type(2) {
        width: 100%;
      }
    }
  }
}
