@import "../../styleFunctions/mixins";

.resetPasswordPage {
  @include flexContainer($gap: 0);
  min-height: 50rem;
  .resetPasswordBox {
    width: 100%;
    max-width: 20%;
    form {
      @include flexContainer($gap: 4rem);
      flex-direction: column;
      > div {
        @include flexContainer($gap: 0.5rem);
        flex-direction: column;
        width: 100%;
        position: relative;
        .errorText {
          position: absolute;
          bottom: -3.5rem;
        }
        label,
        input {
          width: 100%;
        }
        label {
          font-weight: 600;
          font-size: 1.6rem;
        }
        input {
          width: 100%;
          border: 2px solid var(--primaryColor);
          padding: 0.7rem 1.5rem;
          border-radius: 0.8rem;
          font-size: 1.7rem;
          &:focus {
            outline: none;
          }
        }
        .passwordInputContainer {
          width: 100%;
          position: relative;
          input  {
            padding-right: 37px;
          }
          > span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            cursor: pointer;
          }
        }
      }
      button {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .resetPasswordPage {
    .resetPasswordBox {
      max-width: 80%;
    }
  }
}
