.searchContainer {
    position: relative;
    max-width: 70%;
    margin: 0 auto;
    border-radius: 0.7rem;
      outline: none;
      border: none;
      padding: 1.2rem 2rem;
      width: 100%;
      background-color: var(--secondaryGreyColor);
      font-size: 1.6rem;
    // input {
    //   border-radius: 0.7rem;
    //   outline: none;
    //   border: none;
    //   padding: 1.8rem 2rem;
    //   width: 100%;
    //   background-color: var(--secondaryGreyColor);
    //   font-size: 1.6rem;
    //   &::placeholder {
    //     color: #a5a7aa;
    //     font-weight: 600;
    //   }
    // }
    button {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      padding: 1rem !important;
    }
  }

  @media only screen and (max-width: 590px) {
    .searchContainer {
      max-width: 100%;
    }
  }