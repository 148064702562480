@import "../../styleFunctions/mixins";

.inStorePage {
  .oneStopSection {
    .oneStopInfo {
      > div {
        text-align: center;
        img {
          max-width: 30rem;
          margin-bottom: 1.5rem;
        }
      }
      > p {
        max-width: 55rem;
        margin: 3rem auto 0;
      }
    }
    .oneStopSlider {
      .oneStopImgContainer {
        padding: 1rem;
        img {
          border-radius: 2rem;
        }
      }
    }
  }
  .promotionSection {
    min-height: 511px;
    .promotionBox {
      min-height: 20rem;
      aspect-ratio: 1;
      background-color: var(--secondaryColor);
      position: relative;
      cursor: pointer;
      .promotionLabel {
        background-color: var(--tertiaryColor);
        width: 100%;
        max-width: 50%;
        padding: 1rem 0 1rem 2rem;
        margin: 0 0 0 auto;
        position: absolute;
        bottom: 2rem;
        right: 0;
        h2 {
          color: var(--whiteColor);
          font-size: 1.6rem;
          font-style: italic;
          line-height: 2.6rem;
          margin: 0;
        }
      }
    }
    .activePromotion {
      &:after {
        content: "";
        width: 9rem;
        height: 4rem;
        background-color: #edeef0;
        position: absolute;
        bottom: -10rem;
        left: 50%;
        transform: translate(-50%, 0);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      }
    }
    .promotionsBlock {
      @include backgroundImage($image: url(../../assets/promotions.png));
    }
    .chillerzBlock {
      @include backgroundImage($image: url(../../assets/chillerz.png));
    }
    .foodBlock {
      @include backgroundImage($image: url(../../assets/food2Go.png));
    }
  }
  .promotionSliderSection {
    overflow: hidden;
    padding-top: 0;
    > div {
      padding: 0;
    }
    .promoImgContainer {
      position: relative;
      img {
        padding: 2rem;
      }
      &:after {
        content: "";
        width: calc(100% - 4rem);
        height: 100%;
        background-color: #002c747d;
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .mobilePromoSlider {
    display: none;
  }
  .activePromotionSlider {
    background-color: #edeef0;
    margin-top: 10rem;
    padding-block: 5rem;
  }
  .secondSection {
    padding-block-end: 10rem;
    h2 {
      margin: 0;
    }
    .sectionImg {
      text-align: center;
      margin-bottom: 2rem;
      img {
        max-width: 6rem;
      }
    }
    .secDescriptionBlock {
      max-width: 70%;
      margin: 0 auto;
      p {
        margin: 2.5rem 0;
      }
    }
  }
  .thirdSection {
    @include backgroundImage($image: url(../../assets/inStoreBg.jpg));
    @include flexContainer;
    min-height: 40rem;
    padding-block-start: 0rem;
    .thirdSectionRow {
      justify-content: flex-end;
      padding-inline: 2rem;
      overflow: hidden;
      .thirdDescription {
        padding-inline-start: 3.5rem;
        a {
          margin-block-start: 2rem;
          max-width: fit-content;
        }
      }
    }
  }
  .fourthSection {
    padding-block-end: 10rem;
    .fourthDescription {
      p {
        margin-block: 2rem;
      }
    }
    .fourthImg {
      text-align: center;
      overflow: hidden;
      img {
        max-width: 40rem;
        filter: drop-shadow(0px 5px 6px rgb(0, 0, 0, 0.5));
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .inStorePage {
    .oneStopSection {
      > div {
        flex-direction: column-reverse;
        .oneStopInfo {
          margin-top: 6rem;
        }
      }
    }
    .promotionSection {
      > div {
        justify-content: center;
        > div {
          padding: 2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .inStorePage {
    .secondSection {
      .secDescriptionBlock {
        max-width: 100%;
      }
    }
    .thirdSection {
      .thirdSectionRow {
        .thirdDescription {
          padding-inline: 0;
        }
      }
    }
    .fourthSection {
      .fourthImg {
        margin-top: 4rem;
      }
    }
    .promotionSliderSection {
      display: none;
    }
    .mobilePromoSlider {
      display: block !important;
    }
  }
}
