@import "../../../styleFunctions/mixins";

.timelineBlock {
  @include flexContainer($gap: calc(4rem + 3.5rem));
  @include backgroundImage($image: url(../../../assets/timelineMap.png));
  background-size: contain;
  padding-block: 10rem;
  max-width: 95rem;
  margin: 0 auto;
  .yearBlock {
    width: 100%;
    min-height: 27rem;
    @include flexContainer;
    flex-direction: column;
    position: relative;
    &:first-child,
    &:last-child {
      color: var(--primaryColor);
      border-color: var(--primaryColor) !important;
      .yearLabel {
        background-color: var(--primaryColor) !important;
        &:after {
          border-color: var(--primaryColor) !important;
        }
        &:before {
          background-color: var(--primaryColor) !important;
        }
      }
    }
    &:last-child {
      .yearLabel {
        &:before {
          display: none;
        }
      }
    }
    &:nth-child(even) {
      color: var(--secondaryColor);
      border-color: var(--secondaryColor);
      .yearLabel {
        background-color: var(--secondaryColor);
        &:after {
          border-color: var(--secondaryColor);
        }
        &:before {
          background-color: var(--secondaryColor);
        }
      }
      .yearContent {
        bottom: 0;
      }
    }
    &:nth-child(odd) {
      color: var(--tertiaryColor);
      border-color: var(--tertiaryColor);
      .yearLabel {
        background-color: var(--tertiaryColor);
        &:after {
          border-color: var(--tertiaryColor);
        }
        &:before {
          background-color: var(--tertiaryColor);
        }
      }
      .yearContent {
        top: 0;
      }
    }
    .yearContent {
      color: inherit;
      border-left: 0.3rem solid;
      border-color: inherit !important;
      height: 10rem;
      padding-left: 1rem;
      width: 100%;
      position: absolute;
      &:after {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border: 0.5rem solid;
        border-color: inherit !important;
        border-radius: 50%;
        position: absolute;
        bottom: 0px;
        left: -6.05px;
      }
      &:before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border: 0.5rem solid;
        border-color: inherit !important;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: -6.05px;
      }
      h2 {
        font-size: 1.4rem;
        color: inherit;
        text-align: center;
        line-height: normal;
        margin: 0;
      }
      p {
        font-size: 0.9rem;
        color: var(--secondaryColor);
        text-align: center;
        margin: 0.5rem 0 0;
      }
    }
    .yearLabel {
      width: 100%;
      position: relative;
      padding: 0.8rem;
      &:after {
        content: "";
        width: 2rem;
        height: 100%;
        border: 1.9rem solid var(--primaryColor);
        position: absolute;
        top: 0;
        right: -3.5rem;
        clip-path: polygon(23% 0, 100% 50%, 24% 100%, 0% 100%, 0 47%, 0% 0%);
      }
      &:before {
        content: "";
        width: calc(4rem + 3.5rem);
        height: 0.2rem;
        position: absolute;
        right: -7.5rem;
        top: 50%;
        transform: translate(0, -50%);
      }
      h2 {
        color: var(--whiteColor);
        font-size: 2rem;
        text-align: center;
        margin: 0;
        line-height: normal;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .timelineBlock {
    flex-direction: column;
    .yearBlock {
      min-height: unset;
      &:after {
        content: "";
        position: absolute;
        width: 0.2rem;
        height: 11.5rem;
        bottom: -7.5rem;
        right: 50%;
        transform: translate(-50%, 0);
        background-color: var(--primaryColor);
      }
      &:nth-child(even) {
        align-items: flex-end;
        .yearContent {
          left: 0;
          border-left: none;
          border-right: 0.3rem solid;
          padding-left: 0;
          padding-right: 1rem;
          &:before,
          &:after {
            right: -6.05px;
            left: unset;
          }
        }
        .yearLabel {
          &:after {
            left: -3.5rem;
            right: unset;
            clip-path: polygon(
              100% 0%,
              100% 49%,
              100% 100%,
              85% 100%,
              0 49%,
              85% 0
            );
          }
          &:before {
            left: -4.4rem;
          }
        }
      }
      &:nth-child(odd) {
        align-items: flex-start;
        .yearContent {
          right: 0;
          bottom: unset;
        }
        .yearLabel {
          &:before {
            right: -4.1rem;
          }
        }
      }
      &:last-child {
        &:after {
          height: 5.5rem;
          top: 0;
          bottom: unset;
        }
        .yearLabel {
          &:before {
            display: block;
          }
        }
      }
      .yearContent {
        max-width: 13rem;
        height: 8rem;
        top: -50% !important;
      }
      .yearLabel {
        max-width: calc(50% - 3.7rem);
        &:before {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          z-index: 1;
        }
      }
    }
  }
}
