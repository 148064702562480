@import "../../../styleFunctions/mixins";

.sixthSection {
  padding-block-end: 10rem;
  .sixthSectionCol {
    @include flexContainer($justifyContent: flex-start, $gap: 0);
    overflow: hidden;
    > div {
      &:nth-of-type(1) {
        z-index: 1;
        img {
          max-width: 50rem;
        }
      }
      &:nth-of-type(2) {
        background-color: var(--primaryColor);
        padding: 2rem 7rem 2rem 12rem;
        border-radius: 2.1rem;
        margin-inline-start: -6rem;
        z-index: 0;
        h2 {
          margin-bottom: 2rem;
        }
      }
      .downloadApp {
        @include flexContainer($justifyContent: flex-start);
        margin-block-start: 2rem;
        a {
          display: block;
          width: 100%;
          max-width: 20rem;
          img {
            min-height: 6.2rem;
            object-fit: cover;
            border-radius: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .sixthSection {
    .sixthSectionCol {
      flex-direction: column;
      > div {
        &:nth-of-type(1) {
          img {
            max-width: 50rem;
          }
        }
        &:nth-of-type(2) {
          margin-inline-start: 0rem;
          padding: 4rem;
          margin-block-start: 3rem;
        }
        .downloadApp {
            flex-direction: column;
            align-items: flex-start;
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .sixthSection {
    .sixthSectionCol {
      flex-direction: column;
      > div {
        &:nth-of-type(2) {
          margin-inline-start: 0rem;
          padding: 4rem;
          margin-block-start: 3rem;
        }
      }
    }
  }
}
