@import "../../../styleFunctions/mixins";

header {
  z-index: 1999999999;
  .topHeader {
    background-color: var(--secondaryGreyColor);
    padding-block: 1rem;
    .topHeaderInner {
      @include flexContainer($justifyContent: flex-end, $gap: 0);
      ul {
        @include flexContainer($justifyContent: flex-start, $gap: 3em);
        li {
          font-size: 1.4rem;
          color: var(--navItemColor);
          position: relative;
          font-weight: 500;
          &:after {
            content: "";
            width: 0.1rem;
            height: 100%;
            background-color: var(--ternaryGreyColor);
            position: absolute;
            top: 0;
            right: -1rem;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          a {
            color: inherit;
          }
        }
      }
    }
  }
  .bottomHeader {
    @include flexContainer($justifyContent: flex-start, $gap: 0);
    background-color: var(--primaryColor);
    height: 10rem;
    h1 {
      color: #fff;
      font-size: 5rem;
    }
    > div {
      > div {
        align-items: center;
        .brandLogo {
          img {
            width: 100%;
            max-width: 25rem;
          }
        }
        .mainMenu {
          @include flexContainer($gap: 3rem);
          li {
            a {
              color: inherit;
              text-transform: capitalize;
              position: relative;
              &:after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                background-color: var(--whiteColor);
                bottom: -0.4rem;
                left: 0;
                transform: scale(0);
                transition: all 0.2s ease-in-out;
              }
              &:hover {
                &:after {
                  transform: scale(1);
                  transform-origin: center;
                }
              }
            }
          }
        }
        .headerColThree {
          @include flexContainer($justifyContent: flex-end, $gap: 2rem);
          position: relative;
          ul {
            @include flexContainer($justifyContent: flex-end, $gap: 2rem);
            li {
              color: var(--whiteColor);
              font-size: 1.4rem;
              font-weight: 500;
              a {
                @include flexContainer($justifyContent: flex-end, $gap: 0.8rem);
                position: relative;
                &:after {
                  content: "";
                  width: 100%;
                  height: 2px;
                  position: absolute;
                  background-color: var(--whiteColor);
                  bottom: -0.4rem;
                  left: 0;
                  transform: scale(0);
                  transition: all 0.2s ease-in-out;
                }
                &:hover {
                  &:after {
                    transform: scale(1);
                    transform-origin: center;
                  }
                }
              }
            }
          }
          .userBlock {
            position: relative;
            &:hover {
              .userMenu {
                transform: scale(1) translateX(-50%);
            }
            }
            .userMenu {
              background-color: var(--whiteColor);
              padding: 1rem;
              position: absolute;
              top: 32px;
              left: 50%;
              box-shadow: 0 0 0.6rem rgb(0, 0, 0, 0.3);
              border-radius: 1rem;
              width: 17rem;
              transform: scale(0) translateX(-50%);
              transform-origin: top center;
              transition: all 0.2s ease-in-out;
              ul {
                margin: 0;
                padding: 0;
                list-style: none;
                justify-content: center;
                flex-direction: column;
                li {
                  width: 100%;
                  button {
                    padding: 1rem 3rem;
                    font-size: 1.4rem;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mobileHeader {
    display: none;
    transition: all 0.5s ease-in-out;
  }
}

@media only screen and (max-width: 1280px) {
  header {
    .topHeader {
      display: none;
    }
    .bottomHeader {
      display: none;
    }
    .mobileHeader {
      @include flexContainer($justifyContent: space-between);
      background-color: var(--primaryColor);
      position: relative;
      height: 10rem;
      padding-inline: 4rem;
      .mobileLogo {
        img {
          max-width: 25rem;
        }
      }
      > div {
        &:nth-of-type(3) {
          label {
            margin: 0 0 0 auto;
          }
        }
      }
      h1 {
        color: #fff;
        font-size: 5rem;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  header {
    .mobileHeader {
      > div {
        width: 100%;
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          flex: 1 1 60%;
        }
        &:nth-of-type(3) {
          flex: 1 1 30%;
        }
      }
    }
  }
}
