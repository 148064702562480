.form_container {
  width: 100%;
  max-width: 50rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 50px 40px 30px;
  background-color: #ffffff;
  box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  font-family: "Inter", sans-serif;
}

.title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.subtitle {
  font-size: 0.725rem;
  max-width: 80%;
  text-align: center;
  line-height: 1.1rem;
  color: #8b8e98;
}

.input_container {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.input_label {
  font-size: 1.4rem;
  color: #8b8e98;
  font-weight: 600;
}

.input_field {
  width: auto;
  height: 40px;
  font-size: 1.6rem;
  padding: 0 0 0 15px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.sign-in_btn {
  width: 100%;
  height: 40px;
  border: 0;
  background: #115dfc;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.errorText {
  position: absolute;
  bottom: -3.5rem;
  left: 0;
}

.form_container .forgotPass p {
  text-align: center;
  font-size: 1.5rem;
}

.form_container p a {
  color: var(--primaryColor) !important;
}

.loginWithBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  button {
    width: 100%;
    padding: 1rem;
    border-radius: 0;
    font-size: 1.6rem;
    border-width: 0 0 2px 0;
    background-color: transparent;
    color: var(--primaryColor);
  }
}

.activeLoginBtn {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
}

.inner_form_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
}

.input_container input[type="number"]::-webkit-outer-spin-button,
.input_container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input_container input[type="number"] {
  -moz-appearance: textfield;
}
