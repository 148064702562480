@import "../../styleFunctions/mixins";

.cancoContest {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/cancoContestBanner.webp));
    min-height: 50rem;
    @include flexContainer;
    > div {
      > div {
        > div {
          img {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            display: block;
          }
        }
      }
    }
  }
  .contestDescription {
    padding-block: 10rem;
    > div {
      > div {
        p {
          text-align: center;
          max-width: 1200px;
          margin: 0 auto 50px;
        }
        img {
          display: block;
          margin: 0 auto;
          max-width: 450px;
        }
      }
    }
  }
  .schoolSpirit {
    background-color: #f9f9f9;
    padding-block-end: 10rem;
    > div {
      > div {
        > div {
          &:nth-of-type(2) {
            padding-left: 40px;
            h2 {
              font-size: 22px;
              margin-bottom: 10px;
            }
            p {
              margin-bottom: 20px;
            }
            .validDate {
              margin: 20px 0 0;
              font-size: 14px;
            }
            a {
              display: inline-flex;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .cancoContest {
    .schoolSpirit {
      > div {
        > div {
          > div {
            &:nth-of-type(2) {
              padding-left: 0;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
