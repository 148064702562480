.timingData {
    position: absolute;
    background: #fff;
    top: 34px;
    width: 300px;
    box-shadow: 0 0 6px rgb(0, 0, 0, 0.2);
    border-radius: 1rem;
    padding: 1rem;
    display: none;
    z-index: 2;
    > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      p {
        font-size: 1.4rem !important;
      }
    }
    > div:nth-of-type(1) {
      p {
        font-weight: 600 !important;
      }
    }
  }