@import "../../../../styleFunctions/mixins";

.addCardContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 9999999999;
  top: 0;
  @include flexContainer;
  .addCardInner {
    width: 100%;
    max-width: 60%;
    background-color: var(--whiteColor);
    border-radius: 1.5rem;
    // overflow: hidden;
    img {
      border-radius: 1.5rem;
    }
    position: relative;
    > svg {
      position: absolute;
      top: -19px;
      right: -17px;
      background-color: var(--primaryColor);
      color: #fff;
      width: 35px;
      height: 35px;
      padding: 5px;
      border-radius: 50%;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  .addCardContainer {
    .addCardInner {
      max-width: 90%;
    }
  }
}
