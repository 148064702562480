.menuSliderContainer {
  position: absolute;
  width: 100% !important;
  height: 100%;
  background-color: var(--whiteColor);
  height: calc(100vh - 76px);
  max-height: 130vh;
  overflow-y: auto;
  top: 10rem;
  right: 0;
  z-index: 999;
  // box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border-left: 0.2rem solid var(--secondaryGreyColor);
  .mobileUserBlock {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 3rem 2rem;
    border-bottom: 0.1rem solid var(--primaryGreyColor);
    svg {
      color: var(--primaryColor);
    }
    p {
      margin: 0;
    }
  }
  ul {
    li {
      font-size: 1.8rem;
      color: var(--secondaryColor);
      font-weight: 500;
      a {
        display: block;
        padding: 2rem;
        border-bottom: 0.2rem solid var(--secondaryGreyColor);
        &:after {
          display: none !important;
        }
      }
    }
  }
  .mobileLogout {
    text-align: center;
    padding-top: 4rem;
    button {
      width: 100%;
      max-width: 80%;
      margin: 0 auto;
    }
  }
}
