@import "../../styleFunctions/mixins";

.businessPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/businessBanner.jpg));
    min-height: 45rem;
  }
  .businessSection {
    padding-bottom: 10rem;
    .businessPointer {
      margin: 6rem 0 0;
      > div {
        justify-content: center;
        > div {
          padding: 2rem;
          @include flexContainer;
          flex-direction: column;
          img {
            max-width: 15rem;
            aspect-ratio: 1;
            object-fit: contain;
          }
          p,
          h2 {
            margin: 0;
            text-align: center;
          }
          h2 {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
  .whyCancoSection {
    background-color: var(--secondaryGreyColor);
    padding: 6rem 0;
    > div {
      padding-top: 0;
      > div {
        > div {
          @include flexContainer;
          flex-direction: column;
        }
      }
    }
  }
  .gallerySection {
    padding-top: 0px;
    padding-bottom: 10rem;
    > div {
      > div {
        max-height: 30rem;
        display: flex;
        img {
          object-fit: cover;
          box-shadow: 0 0 6px rgb(0,0,0,0.5);
        }
      }
    }
    .firstGalleryRow {
      > div {
        img {
          object-fit: cover;
        }
        // &:nth-of-type(1) {
        //   @include backgroundImage($image: url(../../assets/CA-gallery-01.png));
        // }
        // &:nth-of-type(2) {
        //   @include backgroundImage($image: url(../../assets/CA-gallery-02.png));
        // }
        // &:nth-of-type(3) {
        //   @include backgroundImage($image: url(../../assets/CA-gallery-03.png));
        // }
      }
    }
    .secondGalleryRow, .thirdGalleryRow {
      margin-top: 1.5rem;
    }
  }
  .connectAndExpressCard {
    @include backgroundImage($image: url(../../assets/roadBg.png));
    padding: 5rem 0;
    > div {
      padding-top: 0;
    }
    .connectExpressCol {
      position: relative;
      text-align: center;
      img {
        max-width: 20rem;
        margin-bottom: 1.5rem;
      }
      a {
        max-width: 20rem;
        margin: 1.5rem auto 0;
      }
      &:nth-of-type(1) {
        > div {
          a {
            margin-top: 4rem;
          }
        }
      }
      &:nth-of-type(2) {
        &:after {
          display: none;
        }
      }
      &:after {
        content: "";
        position: absolute;
        width: 0.1rem;
        height: 100%;
        background-color: var(--whiteColor);
        top: 0;
        right: 0;
      }
      > div {
        max-width: 40rem;
        margin: 0 auto;
      }
      h2,
      p {
        text-align: center;
        color: var(--whiteColor);
        margin: 1rem 0;
      }
      h2 {
        font-size: 2.2rem;
        line-height: 3.2rem;
      }
      p {
        font-size: 1.6rem;
      }
      button {
        margin: 0 auto;
      }
    }
  }
  .canadaSection {
    padding-top: 0px;
    > div {
      > div {
        padding: 3rem 0;
        text-align: center;
        img {
          width: 100%;
          max-width: 6rem;
          margin-bottom: 2rem;
        }
        h2 {
          text-align: center;
          font-size: 2.8rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .businessPage {
    .connectAndExpressCard {
      .connectExpressCol {
        &:after {
          width: 100%;
          height: 0.1rem;
          top: unset;
          bottom: -3rem;
        }
        &:nth-of-type(1) {
          margin-bottom: 6rem;
        }
      }
    }
  }
}
