@import "../../../../styleFunctions/mixins";

.accountDeletionBlock {
  .becomeRealtorForm {
    @include flexContainer;
    flex-direction: column;
    > div {
      @include flexContainer($gap: 2rem);
      width: 100%;
      &:nth-of-type(1) {
        > div {
          label {
            font-weight: 500;
          }
        }
      }
      > div {
        @include flexContainer($gap: 0.5rem, $alignItems: flex-start);
        flex-direction: column;
        width: 100%;
        position: relative;
        .errorText {
          position: absolute;
          bottom: -3.5rem;
          left: 0;
        }
      }
    }
    > button {
      margin-top: 22px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .accountDeletionBlock {
    .becomeRealtorForm {
      > div {
        &:nth-of-type(1) {
          > div {
            label {
              span {
                width: 29px;
              }
            }
          }
        }
      }
    }
  }
}
