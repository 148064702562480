@import "../../../../styleFunctions/mixins";

.becomeVendorForm {
  margin-top: 5rem;
  > div {
    @include flexContainer($gap: 3rem);
    width: 100%;
    > div {
      @include flexContainer($gap: 0.5rem);
      flex-direction: column;
      width: 100%;
      position: relative;
      .errorText {
        position: absolute;
        bottom: -3.5rem;
        left: 0;
      }
    }
  }
  .formBtnContainer {
    button {
      &:nth-of-type(1) {
        background-color: var(--secondaryColor);
        border-color: var(--secondaryColor);
        &:hover {
          background-color: transparent;
          color: var(--secondaryColor);
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .becomeVendorForm {
    > div {
      flex-direction: column;
    }
  }
}
