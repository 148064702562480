@import "../../../../styleFunctions/mixins";

.becomeRealtorForm {
  > div {
    @include flexContainer($gap: 2rem);
    width: 100%;
    > div {
      @include flexContainer($gap: 0.5rem);
      flex-direction: column;
      width: 100%;
      position: relative;
      textarea {
        width: 100%;
        width: 100%;
        font-size: 1.6rem;
        padding: 1rem;
        border-radius: 0.7rem;
        outline: none;
        border: 0.1rem solid rgb(229, 229, 229);
        filter: drop-shadow(rgb(239, 239, 239) 0px 1px 0px)
          drop-shadow(rgba(239, 239, 239, 0.5) 0px 1px 0.5px);
        transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1) 0s;
      }
      .errorText {
        position: absolute;
        bottom: -3.5rem;
        left: 0;
      }
    }
  }
}
