@import "../../../styleFunctions/mixins";

.tableFooter {
  @include flexContainer($justifyContent: space-between);
  padding: 3rem 2rem;
  .pagination {
    @include flexContainer($justifyContent: space-between, $gap: 3rem);
    button {
      font-size: 1.4rem;
      font-weight: 500;
      padding: 0.7rem 1.7rem;
      background: none;
      border: none;
      border-radius: 0.5rem;
      &:hover {
        background: transparent
          linear-gradient(
            90deg,
            #f7f7f7 0%,
            #f6f6f6 1%,
            #e8e8e8 28%,
            #e4e3e4 50%,
            #e9e8e9 71%,
            #f8f8f8 97%,
            #fbfbfb 100%
          )
          0% 0% no-repeat padding-box;
      }
    }
    .activeButton {
      background: transparent
        linear-gradient(
          90deg,
          #f7f7f7 0%,
          #f6f6f6 1%,
          #e8e8e8 28%,
          #e4e3e4 50%,
          #e9e8e9 71%,
          #f8f8f8 97%,
          #fbfbfb 100%
        )
        0% 0% no-repeat padding-box;
        border: 0.1rem solid #aaaaaa;
    }
  }
}

@media only screen and (max-width: 590px) {
  .tableFooter {
    flex-direction: column;
    .displayingData {
      display: none;
    }
  }
}
