@import "../../../../styleFunctions/mixins";

.becomeRealtorForm {
  > div {
    @include flexContainer($gap: 2rem);
    width: 100%;
    > div {
      @include flexContainer($gap: 0.5rem);
      flex-direction: column;
      width: 100%;
      position: relative;
      .errorText {
        position: absolute;
        bottom: -3.5rem;
        left: 0;
      }
    }
  }
}
