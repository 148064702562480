@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  --primaryColor: #ff4d00;
  --primaryLightColor: rgb(252 76 2 / 60%);
  --secondaryColor: #231f20;
  --tertiaryColor: #1d376c;
  --navItemColor: #414042;
  --whiteColor: #ffffff;
  --highlightColor: #46c6ed;
  --primaryGreyColor: #a5a7aa;
  --secondaryGreyColor: #f3f4f5;
  --ternaryGreyColor: #e9e9e9;
  --fontFamily: "Montserrat";
}

@font-face {
  font-family: frutigerBold;
  src: url(./styleFunctions/font/ftb.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
  color: inherit !important;
  font-size: inherit;
}

img {
  width: 100%;
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

/* Active Nav Link */

.active {
  position: relative;
  display: block;
  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: var(--whiteColor);
    bottom: -0.3rem;
    left: 0;
    transform: scale(1) !important;
  }
}

.slick-track {
  overflow: hidden !important;
}

.slick-dots {
  bottom: 1.4rem !important;
}

.slick-dots li button:before {
  font-size: 1.4rem !important;
}

.aboutSlider .slick-dots {
  bottom: -2.8rem !important;
}

.modalOpen {
  overflow: hidden;
}

.bounceBtn {
  -webkit-animation: bounce-top 2s 1s infinite alternate both;
  animation: bounce-top 2s 1s infinite alternate both;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/** Bounce Btn Keyframes **/
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
