@import "../../styleFunctions/mixins";

.cancoContest {
  // .bannerSection {
  //   @include backgroundImage($image: url(../../assets/schoolSpiritBanner.webp));
  //   min-height: 60rem;
  // }
  .contestDescription {
    padding-block: 10rem;
    > div {
      > div {
        h2 {
          text-align: center;
        }
        p {
          text-align: center;
          max-width: 1200px;
          margin: 20px auto 50px;
        }
        img {
          display: block;
          margin: 0 auto;
          max-width: 950px;
        }
      }
    }
  }
  .schoolSpirit {
    background-color: #f2f2f2;
    padding-block-end: 10rem;
    > div {
      > div {
        > div {
          p {
            text-align: center;
          }
          .formBlock {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .spiritTermsCondition {
    padding-block-end: 10rem;
    > div {
      > div {
        h2 {
          text-align: center;
          border-bottom: 1px solid #cacaca;
          padding-bottom: 10px;
        }
        .conditionContainer {
          margin-top: 20px;
          > div {
            justify-content: center;
            margin-bottom: 20px;
            > div {
              h3 {
                text-align: right;
                font-weight: 600;
                color: #c4c4c4;
              }
              &:nth-of-type(1) {
                padding-right: 20px;
              }
              &:nth-of-type(2) {
                padding-left: 20px;
              }
              h3,
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .cancoContest {
    .bannerSection {
      min-height: 18rem;
    }
    .contestDescription {
      padding-top: 0px;
    }
    .schoolSpirit {
      > div {
        > div {
          > div {
            &:nth-of-type(2) {
              padding-left: 0;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .spiritTermsCondition {
      > div {
        > div {
          .conditionContainer {
            > div {
              > div {
                h3 {
                  text-align: left;
                }
                &:nth-of-type(1) {
                  padding-right: 0px;
                }
                &:nth-of-type(2) {
                  padding-left: calc(var(--bs-gutter-x) * 0.5);
                }
              }
            }
          }
        }
      }
    }
  }
}
